.Sidebar {
  width: 20%;
  height: 100%;
  position: fixed;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.Sidebar .title {
  color: #a67c00;
  font-family: 'Indie Flower', cursive;
}

.Sidebar .menuLinks {
  padding-top: 10px;
}

.Sidebar .menuLinks li {
  padding: 20px 0;
}

.Sidebar .menuLinks a {
  transition: 200ms all ease;
  color: #ffffff;
  padding: 0 30px;
}

.Sidebar .menuLinks hr {
  height: 1px;
  margin: 40px 0;
}

.Sidebar .menuLinks a:hover {
  opacity: 0.3;
}
