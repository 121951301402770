.skillsContainer {
  padding-top: 5vh;
  text-align: center;
  height: 100vh;
  flex-direction: column;
}

.skillsContainer .columns {
  justify-content: flex-start;
}

.skillsContainer > h1 {
  color: #f29202;
}

.skillsContainer tspan {
  fill: white;
}

.skillsTextContainer {
  padding-top: 15vh !important;
}

.skillsText {
  text-align: justify;
}
