.footerContainer {
  height: 60px;
  width: 100vw;
  padding-left: 30%;
  padding-right: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(0, 11, 22, 1);
  display: flex;
  align-items: center;
}

.footerContainer > h1 {
  color: rgba(244, 244, 255, 0.2);
}
