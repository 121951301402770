.welcomeContainer {
  padding-top: 15vh;
  height: 96vh;
}

.welcome {
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
}

.welcome h1 {
  margin-top: 20px;
  color: #dddddd;
}

.welcome h2 {
  color: #f29202;
  font-weight: bold;
}
