.contactContainer {
  padding-top: 5vh;
  text-align: center;
  height: 91vh;
  display: flex;
  flex-direction: column;
}

.contactSubContainer {
  display: flex;
  justify-content: space-around;
}

.contactForm {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.contactFormText {
  text-align: justify;
}

.contactContainer > h1 {
  color: #f29202;
}

.contactLabel {
  float: left;
  color: #f29202 !important;
}

.sideBySide {
  justify-content: space-evenly;
  width: 100% !important;
}

.btnSubmit {
  margin-top: 2vh !important;
}

.btnSubmit button {
  transition: all 300ms !important;
}

.btnSubmit button:hover {
  opacity: 0.6 !important;
}
